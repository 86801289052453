<div class="flex gap-2 pb-4">
    <div [routerLink]="['/experts']" class="w-fit">
    <span nz-icon nzType="left" nzTheme="outline"></span>
    </div>
    <h1>Add Experts</h1>
</div>
<div class="flex flex-col gap-6 rounded-lg bg-white p-4 w-full">
    <nz-spin [nzSpinning]="pageLoading" >
    <nz-divider nzText="Expert Info"></nz-divider>
    <div class="grid grid-cols-4 gap-4" >
      <div class="flex flex-col w-full">
          <label class="form-label">Select Title :</label>
          <nz-select
            [(ngModel)]="title"
            nzPlaceHolder="Select Title"
          >
          <nz-option nzValue="Mr" nzLabel="Mr"></nz-option>
          <nz-option nzValue="Ms" nzLabel="Ms"></nz-option>
          <nz-option nzValue="Mrs" nzLabel="Mrs"></nz-option>
          </nz-select>
        </div>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div class="flex flex-col w-full">
          <label class="form-label">First Name :</label>
          <input
              nz-input
              type="text"
              placeholder="Enter First Name"
              [(ngModel)]="firstName"
              class="w-full"
          />
      </div>
      <div class="flex flex-col w-full">
          <label class="form-label">Last Name :</label>
          <input
              nz-input
              type="text"
              placeholder="Enter Last Name"
              [(ngModel)]="lastName"
              class="w-full"
          />
      </div>
      <div class="flex flex-col w-full">
          <label class="form-label">Email :</label>
          <input
              nz-input
              type="text"
              placeholder="Enter Email"
              [(ngModel)]="email"
              class="w-full"
          />
      </div>
      <div class="flex flex-col w-full">
          <label class="form-label">Phone No :</label>
          <input
              nz-input
              type="text"
              placeholder="Enter Phone Number"
              [(ngModel)]="phoneNo"
              class="w-full"
          />
      </div>
  </div>
  <div class="grid grid-cols-2 gap-2">
    <div class="flex flex-col gap-1">
        <label class="form-label">Profile Pic</label>
        <img  *ngIf="profilePic" [src]="profilePic" alt="ProfilePic"/>
        <input
        nz-input
        (change)="uploadTherapistImage($event)"
        accept=".jpg,.jpeg"
        type="file"
        required
        />
    </div>
    <div class="flex flex-col gap-1">
      <label class="form-label">Video Bio</label>
      <video *ngIf="videoSrc" controls [src]="videoSrc">
          Your browser does not support the video tag.
        </video>
      <input
      nz-input
      (change)="uploadVideoImage($event)"
      accept=".mp4"
      type="file"
      required
      />
  </div>
  </div>
  <nz-divider nzText="Therapy info"></nz-divider>
<div class="grid grid-cols-3 gap-3">
      <div class="w-full">
        <label class="form-label required">Expert consultation</label>
        <nz-switch
          [(ngModel)]="isTherapist"
        ></nz-switch>
      </div>
</div>

<div class="grid grid-cols-4 gap-2">
    <div class="flex flex-col w-full">
      <label class="form-label">Select Designation Level :</label>
      <nz-select
        [(ngModel)]="selectedTherapistLevel"
        nzPlaceHolder="Select Designation Level"
      >
        <nz-option
          *ngFor="let vertical of therapistExpertLevels"
          [nzValue]="vertical.data"
          [nzLabel]="vertical.data"
        ></nz-option>
      </nz-select>
    </div>
    <div class="w-full">
      <label class="form-label">Rate Therapist :</label>
      <nz-rate
        [(ngModel)]="rating"
      ></nz-rate>
    </div>
    <div>
      <label class="form-label">Max Pricing :</label>
      <input
        nz-input
        type="number"
        placeholder="Enter Max Pricing"
        [(ngModel)]="maxPricing"
        class="w-full"
      />
    </div>
    <div>
      <label class="form-label">Min Pricing :</label>
      <input
        nz-input
        type="number"
        placeholder="Enter Min Pricing"
        [(ngModel)]="minPricing"
        class="w-full"
      />
    </div>
  </div>
  <div class="grid grid-cols-3 gap-3">
    <div>
      <label class="form-label">TagLine</label>
      <input
        nz-input
        type="text"
        placeholder="Enter Tag Line"
        [(ngModel)]="tagLine"
        class="w-full"
      />
    </div>
    <div>
        <label class="form-label required">Languages</label>
        <nz-select
        class="w-full"
        [(ngModel)]="selectedLanguage"
        nzPlaceHolder="Select Languages"
        nzMode="multiple"
      >
        <nz-option
          *ngFor="let expItem of languages"
          [nzLabel]="expItem.data"
          [nzValue]="expItem.value"
        ></nz-option>
      </nz-select>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2">
    <div>
        <label class="form-label required">About</label>
        <textarea rows="4" nz-input [(ngModel)]="about"></textarea>
    </div>
    <div>
        <label class="form-label required">Years Of Experiance</label>
        <input
        nz-input
        type="number"
        placeholder="Enter Year Of Experiance"
        [(ngModel)]="yearOfExperiance"
        class="w-full"
      />
    </div>
  </div>
  
  <nz-divider nzText="Vertical and Concern"></nz-divider>
  <div class="grid grid-cols-2 gap-3">
    <div
      *ngFor="let item of expertiseLevelArray; index as i"
      class="border border-gray-500 rounded-lg p-5"
    >
      <div class="flex flex-row justify-between gap-3">
        <nz-select
          class="w-[130%]"
          [ngModel]="item.verticalId"
          nzPlaceHolder="Select Vertical"
          (ngModelChange)="verticalChange(i, $event)"
        >
          <nz-option
            *ngFor="let vertical of verticalsMasterDataFiltered"
            [nzValue]="vertical.value"
            [nzLabel]="vertical.label"
            [nzDisabled]="vertical.disabled"
          ></nz-option>
        </nz-select>
        <!--Delete button if not last element-->
        <button
          type="button"
          nz-button
          nzType="primary"
          nzShape="circle"
          *ngIf="i !== expertiseLevelArray.length - 1"
          (click)="deleteVertical(i)"
        >
          <span nz-icon nzType="minus" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="
            i === expertiseLevelArray.length - 1 &&
            verticalsMasterDataFiltered.length
          "
          type="button"
          nz-button
          nzType="primary"
          nzShape="circle"
          (click)="addVertical()"
        >
          <span nz-icon nzType="plus" nzTheme="outline"></span>
        </button>
      </div>
      <br />
      <div class="flex flex-col gap-3">
        <div *ngFor="let concern of item.concerns; index as j">
          <div class="flex flex-row flex-1 gap-3">
            <nz-select
              class="w-[100%]"
              [ngModel]="concern.concernsId"
              nzPlaceHolder="Select Concern"
              (ngModelChange)="concernChange(i, j, $event)"
            >
              <nz-option
                *ngFor="let cItem of item.concernsList"
                [nzLabel]="cItem.label"
                [nzValue]="cItem.value"
                [nzDisabled]="cItem.disabled"
              ></nz-option>
            </nz-select>
            <nz-select
              [ngModel]="concern.expertLevelId"
              class="w-[100%]"
              nzPlaceHolder="Select Expertise"
              (ngModelChange)="expertLevelChange(i, j, $event)"
            >
              <nz-option
                *ngFor="let expItem of expertLevelMasterData"
                [nzLabel]="expItem.label"
                [nzValue]="expItem.value"
              ></nz-option>
            </nz-select>
            <button
              type="button"
              nz-button
              nzType="primary"
              nzShape="circle"
              *ngIf="j !== item.concerns.length - 1"
              (click)="deleteConcern(i, j)"
            >
              <span nz-icon nzType="minus" nzTheme="outline"></span>
            </button>

            <button
              *ngIf="
                j === item.concerns.length - 1 &&
                item.concernsList.length
              "
              (click)="addConcern(i)"
              type="button"
              nz-button
              nzType="primary"
              nzShape="circle"
            >
              <span nz-icon nzType="plus" nzTheme="outline"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nz-divider nzText="Circle Expert info"></nz-divider>
  <div class="grid grid-cols-3 gap-3">
    <div class="w-full">
        <label class="form-label required">Circle Expert</label>
        <nz-switch
          [(ngModel)]="isExpert"
        ></nz-switch>
      </div>
</div>
  <div class="grid grid-cols-2 gap-3" >
    <div class="flex flex-col w-full">
      <label class="form-label">Select User Interest :</label>
      <nz-select
        [(ngModel)]="selectedUserInterests"
        nzPlaceHolder="Select User Interests"
        nzMode="multiple"
      >
        <nz-option
          *ngFor="let item of userInterestTopics"
          [nzLabel]="item.value"
          [nzValue]="item.key"
        ></nz-option>
      </nz-select>
    </div>
  </div>
  <nz-divider nzText="Listener info" ></nz-divider>
  <div class="grid grid-cols-3 gap-3">
      <div class="w-full">
        <label class="form-label required">Listener</label>
        <nz-switch
          [(ngModel)]="isListener"
        ></nz-switch>
      </div>
</div>
    <div class="grid grid-cols-2">
      <div>
          <div class="flex">
              <div
                id="chooseAvatar"
                [ngClass]="
               
                    'flex p-2 items-center border-solid rounded-md upload-pic cursor-pointer mt-1'
                "
                (click)="show()"
              >
                <img
                  *ngIf="avtaar"
                  class="m-2 cursor-pointer"
                  src="../../../../assets/icons/{{ avtaar }}.png"
                />
                <div class="flex flex-col items-center">
                  <p class="text-sm">Choose your avtaar</p>
                  <div class="flex gap-1"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-avatar" id="card" style="display: none">
            <img
              *ngFor="let avatar of avatarImages"
              class="m-2 cursor-pointer"
              src="../../../../assets/icons/{{ avatar.data }}.png"
              (click)="uploadAvatar(avatar.value, avatar.data)"
            />
          </div>
          <div>
              <label class="form-label required">Avatar Name</label>
              <nz-select
              [(ngModel)]="avatarName"
              class="w-[100%]"
              nzPlaceHolder="Select AvatarName"
            >
              <nz-option
                *ngFor="let expItem of avatarImages"
                [nzLabel]="expItem.data"
                [nzValue]="expItem.value"
              ></nz-option>
            </nz-select>
          </div>
    </div>
    <nz-divider nzText="Live info"></nz-divider>
    <div class="grid grid-cols-3 gap-3">   
        <div class="w-full">
          <label class="form-label required"> Live Expert</label>
          <nz-switch [(ngModel)]="isGoAnyoLive"></nz-switch>
        </div>
  </div>
    <div class="grid grid-cols-4 gap-4" >
      <div class="flex flex-col w-full">
        <label class="form-label">Add Expert In Anyo Talks :</label>
        <nz-select
          [(ngModel)]="selectedAnyoTalks"
          nzPlaceHolder="add expert to anyo talks"
          nzMode="multiple"
        >
          <nz-option
            *ngFor="let talks of anyoTalks"
            [nzValue]="talks.value"
            [nzLabel]="talks.data"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  
  <div nz-row class="pt-4" nzJustify="end">
    <div nz-col>
      <button (click)="create()" nz-button nzType="primary">
        Save Experts
      </button>
    </div>
  </div>
</nz-spin>
</div>