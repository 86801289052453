import { Component } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { CirclesService } from '../../../services/circles.service';
import { EventsService } from '../../../services/events.service';
import {
  AnyoEvents,
  EventEntryFee,
  EventPaymentType,
  ExternalSpeakers,
} from '../../../models/events/AnyoEvents';
import * as moment from 'moment';
import { Currencies } from '../../../models/Currencies';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { Tax } from '../../../models/Tax';
import { v4 as uuidv4 } from 'uuid';
import { PartnerTable } from '../../../models/partnerTable';
import { PartnerService } from '../../../services/partner.service';

@Component({
  selector: 'app-edit-event',

  templateUrl: './edit-event.component.html',
  styleUrl: './edit-event.component.scss',
})
export class EditEventComponent {
  pageLoading: boolean = false;
  eventCategories: {
    label: string;
    value: string;
  }[] = [];
  shareImage!: File | null;
  images: File[] = [];
  shareMessage = '';
  eventEntryFee: EventEntryFee[] = [];
  validateForm: FormGroup;
  dateTimeFormat = 'dd-MM-yyyy hh:mm a';
  dateFormat = 'dd-MM-yyyy';
  languages: string[] = [];
  eventPaymentTypes: string[] = Object.values(EventPaymentType);
  circleExpertsList: {
    expertId: string;
    name: string;
  }[] = [];
  guestSpeakerDetails: ExternalSpeakers[] = [];
  selectedExperts: string[] = [];
  eventsId: string = '';
  viewMode = true;
  imagesLinks: string[] = [];
  shareImageLink: string = '';
  active!: boolean;
  maxRegistration!: number;
  videoLink: string = '';
  meetLink: string = '';
  videoFile!: File;
  videoThumnailFile!: File;
  videoThumbnail = '';
  squareImage: File | undefined;
  squareImageLink: string | undefined;
  recurring: boolean = false;
  cronText: string | undefined;
  rrText: string | undefined;
  offlineEvent: boolean = false;
  recurringFrequencyText: string | undefined;
  partners: PartnerTable[] = [];
  selectedPartners: string[] = [];
  corporateOnlyEvent: boolean = false;
  venue: string | undefined;
  constructor(
    private fb: NonNullableFormBuilder,
    private auth: AuthService,
    private toasterService: ToastService,
    private masterData: MasterdataService,
    private circleService: CirclesService,
    private eventsService: EventsService,
    private activatedRoute: ActivatedRoute,
    private fileService: FileUtilsService,
    private router: Router,
    private partnerService: PartnerService,
  ) {
    this.validateForm = fb.group({
      selectedCategory: ['', [Validators.required]],
      eventName: ['', [Validators.required]],
      eventDescription: ['', [Validators.required]],
      eventDuration: [0, [Validators.required, Validators.min(10)]],
      keyPoints: ['', []],
      visibleTime: [new Date(), [Validators.required]],
      maximumRegistrations: [
        0,
        [Validators.required, Validators.min(1), Validators.max(500)],
      ],
      eventDate: [new Date(), [Validators.required]],
      registrationCutOff: [new Date(), [Validators.required]],
      selectedLanguages: [[''], [Validators.required]],
      eventPaymentType: ['', [Validators.required]],
    });

    this.validateForm.disable();
  }

  addEntryFee() {
    const eventEntryFee: EventEntryFee = {
      id: '',
      amount: 0,
      currency: Currencies.INR,
      markupPrice: 0,
      tax: [],
    };
    this.eventEntryFee.push(eventEntryFee);
  }

  deleteImage(index: number | undefined) {
    if (index != undefined) {
      this.imagesLinks.splice(index, 1);
    }
  }

  validateDateInFuture: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return {
        required: true,
      };
    }
    if (!moment(control.value as Date).isAfter(moment())) {
      return {
        error: true,
      };
    }
    return {};
  };

  addGuestSpeaker() {
    this.guestSpeakerDetails.push({
      name: '',
      email: '',
      description: '',
      qualification: '',
      socialMediaLinks: {
        twitter: '',
        web: '',
        youtube: '',
        faceBook: '',
        linkedIn: '',
      },
    });
  }

  deleteGuestSpeaker(index: number) {
    this.guestSpeakerDetails.splice(index);
  }

  getEvents(id: string) {
    this.pageLoading = true;
    this.eventsService.getEvents(id).subscribe({
      next: (value) => {
        this.offlineEvent = value.tags?.includes('OFFLINE_EVENT') || false;
        this.venue = value.venue;
        this.corporateOnlyEvent =
          (value.partners && value.partners.length > 0) || false;
        this.selectedPartners =
          value.partners?.map((partner) => {
            return partner.id;
          }) || [];
        this.recurring = value.tags
          ? value.tags.includes('RECURRING_EVENT')
          : false;
        this.rrText = value.rrRule;
        this.cronText = value.cronText;
        this.squareImageLink = value.squareImage;
        if (value.entryFee.length && value.entryFee) {
          this.eventEntryFee = value.entryFee;
        }
        (this.meetLink = value.meetLink!),
          (this.videoLink = value.videoLink!),
          (this.selectedExperts = value.presenterDetailIds);
        this.imagesLinks = value.images!;
        this.shareImageLink = value.shareImage!;
        this.shareMessage = value.shareMessage!;
        this.guestSpeakerDetails = value.externalSpeakers!;
        this.active = value.active;
        this.videoThumbnail = value.videoThumbnail!;

        this.validateForm.setValue({
          selectedCategory: value.categoryId,
          eventDescription: value.description,
          eventDuration: value.durationMinutes,
          eventDate: value.eventDate,
          keyPoints: value.keyTakeAways,
          selectedLanguages: value.languages.split(','),
          eventName: value.name,
          eventPaymentType: this.mapToPaymentType(value.paymentType),
          registrationCutOff: value.registrationCutOffDate,
          visibleTime: value.visibleFrom,
          maximumRegistrations: value.maxRegistrations,
        });
        this.pageLoading = false;
      },
      error: (error) => {
        this.pageLoading = false;
        const errorBody = error.error as IAnyoError;
        this.toasterService.showAnyoErrorToast(errorBody.description);
      },
    });
  }

  editForm() {
    this.viewMode = false;
    this.validateForm.enable();
  }

  mapToPaymentType(paymentType: string) {
    switch (paymentType) {
      case 'FREE':
        return 'Free';
      case 'PAID':
        return 'Paid';
      case 'CORPORATE_PAID':
        return 'Corporate Paid';
      case 'RETAIL_PAID':
        return 'Retail Paid';
      default:
        throw 'Invalid Event payment type passed';
    }
  }

  async updateEvent() {
    if (this.validateForm.valid) {
      const formValue = this.validateForm.value;

      if (
        this.eventEntryFee.some((value) => value.amount <= 0) &&
        formValue['eventPaymentType'] != 'Free'
      ) {
        this.toasterService.showAnyoErrorToast('Entry Fee should be greater 0');
        return;
      }
      if (!this.selectedExperts.length) {
        this.toasterService.showAnyoErrorToast(
          'Please select at-least one Presenter',
        );
        return;
      }
      const entryFee = this.eventEntryFee.map((value) => {
        const fee: EventEntryFee = {
          id: value.id,
          currency: Currencies.INR,
          markupPrice: value.markupPrice,
          amount: value.amount,
          tax: [] as Tax[],
        };
        return fee;
      });

      if (this.recurring) {
        if (!this.rrText) {
          this.toasterService.showAnyoErrorToast('Please enter recurring text');
          return;
        }
        if (!this.cronText) {
          this.toasterService.showAnyoErrorToast('Please enter cron text');
          return;
        }
      }
      if (this.offlineEvent && !this.venue) {
        this.toasterService.showAnyoErrorToast(
          'Please enter venue for offline event',
        );
        return;
      }
      if (this.corporateOnlyEvent && !this.selectedPartners) {
        this.toasterService.showAnyoErrorToast(
          'Please select at-least one partner for corporate event',
        );
        return;
      }
      const event: AnyoEvents = {
        active: this.active,
        categoryId: formValue['selectedCategory'] as string,
        description: formValue['eventDescription'] as string,
        durationMinutes: formValue['eventDuration'] as number,
        entryFee: entryFee,
        eventDate: moment(formValue['eventDate']).format('x'),
        keyTakeAways: formValue['keyPoints'] as string,
        languages: (formValue['selectedLanguages'] as string[]).join(','),
        name: formValue['eventName'] as string,
        paymentType: formValue['eventPaymentType'] as string,
        presenterDetailIds: this.selectedExperts,
        registrationCutOffDate: moment(formValue['registrationCutOff'])
          .endOf('day')
          .format('x'),
        visibleFrom: moment(formValue['visibleTime'])
          .startOf('day')
          .format('x'),
        externalSpeakers: this.guestSpeakerDetails,
        images: this.imagesLinks,
        shareImage: this.shareImageLink,
        shareMessage: this.shareMessage,
        maxRegistrations: formValue['maximumRegistrations'] as number,
        meetLink: this.meetLink,
        videoThumbnail: this.videoThumbnail,
        videoLink: this.videoLink,
        recurring: this.recurring,
        rrText: this.rrText,
        cronText: this.cronText,
        recurringText: this.recurringFrequencyText,
        partnerIds: this.selectedPartners,
        offlineEvent: this.offlineEvent,
        venue: this.venue,
      };
      if (this.images && this.images.length) {
        const imagesArray = await this.handleMultipleFileUpload(this.images);
        this.imagesLinks.push(...imagesArray);
      }
      if (this.squareImage) {
        const uuid = uuidv4();
        console.log(this.squareImage);
        const extension = this.squareImage.name.split('.').pop();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        const filePath = `events/${uuidWithoutHyphens}/squareImage_.${extension}`;
        const squareImageUploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.squareImage.type,
          ),
        );
        this.toasterService.showSuccess(`Uploading Square Image`);
        await lastValueFrom(
          this.fileService.uploadFile(
            this.squareImage,
            squareImageUploadUrl.url,
          ),
        );
        this.toasterService.showSuccess(`Uploading Square Image completed`);
        event.squareImage = `https://cdn.anyo.app/${filePath}`;
      }
      if (this.shareImage) {
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        const extension = this.shareImage.name.split('.').pop();
        const filePath = `events/${uuidWithoutHyphens}/shareimage_.${extension}`;

        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.shareImage.type,
          ),
        );

        this.toasterService.showSuccess(`Uploading shareImage`);
        await lastValueFrom(
          this.fileService.uploadFile(this.shareImage, uploadUrl.url),
        );
        this.toasterService.showSuccess(`Uploading shareImage completed`);

        event.shareImage = `https://cdn.anyo.app/${filePath}`;
      }
      if (this.videoThumnailFile) {
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        const extension = this.videoThumnailFile.name.split('.').pop();
        const filePath = `events/${uuidWithoutHyphens}/video_thumbnail.${extension}`;

        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.videoThumnailFile.type,
          ),
        );

        this.toasterService.showSuccess(`Uploading Video Thumbnail`);
        await lastValueFrom(
          this.fileService.uploadFile(this.videoThumnailFile, uploadUrl.url),
        );
        this.toasterService.showSuccess(`Uploading Video Thumbnail completed`);

        event.videoThumbnail = `https://cdn.anyo.app/${filePath}`;
      }
      if (this.videoFile) {
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        const extension = this.videoFile.name.split('.').pop();
        const filePath = `events/${uuidWithoutHyphens}/video_file.${extension}`;

        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            this.videoFile.type,
          ),
        );

        this.toasterService.showSuccess(`Uploading video`);
        await lastValueFrom(
          this.fileService.uploadFile(this.videoFile, uploadUrl.url),
        );
        this.toasterService.showSuccess(`Uploading video completed`);

        event.videoLink = `https://cdn.anyo.app/${filePath}`;
      }
      this.pageLoading = true;
      this.eventsService.updateEvents(event, this.eventsId).subscribe({
        next: () => {
          this.pageLoading = false;
          this.router.navigate(['/events']);
          this.toasterService.showSuccess('Event Updated successfully');
        },
        error: (error) => {
          this.pageLoading = false;
          const errorBody = error.error as IAnyoError;
          this.toasterService.showAnyoErrorToast(errorBody.description);
        },
      });
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          this.toasterService.showError(
            'Check Any Field has got Error Message',
          );
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async handleMultipleFileUpload(files: File[]) {
    const uploadedFileUrls: string[] = [];

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        const extension = file.name.split('.').pop();
        const filePath = `events/${uuidWithoutHyphens}/images_.${extension}`;

        const uploadUrl = await lastValueFrom(
          this.fileService.generateUploadUrl(
            AnyoS3Buckets.CDN_BUCKET,
            filePath,
            file.type,
          ),
        );

        this.toasterService.showSuccess(`Uploading images ${i + 1}`);
        await lastValueFrom(this.fileService.uploadFile(file, uploadUrl.url));
        this.toasterService.showSuccess(`Uploading images ${i + 1} completed`);

        const fileUrl = `https://cdn.anyo.app/${filePath}`;
        uploadedFileUrls.push(fileUrl);
      }

      return uploadedFileUrls;
    } catch (e) {
      this.toasterService.showError(e + '');
      throw e;
    }
  }

  uploadImages($event: any) {
    const file = $event.target.files;
    if (file) {
      this.images = file;
    }
  }

  isFree = false;
  IsFree(paymentType: string) {
    if (this.isFree) {
      if (paymentType == 'Free') {
        this.eventEntryFee = [];
      } else {
        this.eventEntryFee = [];
        this.addEntryFee();
      }
    }
    this.isFree = true;
  }

  uploadShareImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.shareImage = file;
    }
  }

  uploadThumbnail($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.videoThumnailFile = file;
    }
  }

  uploadVideo($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.videoFile = file;
    }
  }

  ngOnInit() {
    this.pageLoading = true;
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        const masterData = await lastValueFrom(
          this.masterData.masterDataList(),
        );
        this.languages = masterData.languages.map((value) => value.data);
        this.eventCategories = masterData.eventCategories.map((value) => {
          return {
            value: value.m_id,
            label: value.data,
          };
        });
        const circleMasterDataResponse = await lastValueFrom(
          this.circleService.getCircleMasterData(),
        );
        this.circleExpertsList = circleMasterDataResponse.circleExperts.map(
          (value) => {
            return {
              expertId: value.name,
              name: value._id,
            };
          },
        );
        const id = this.activatedRoute.snapshot.params['id'];
        this.eventsId = id;
        this.getEvents(id);
        this.partners = await this.partnerService.getAllActivePartners();
        this.pageLoading = false;
      }
    });
  }
  uploadThumbnailImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.squareImage = file;
    }
  }
  deleteSquareImage() {
    this.squareImage = undefined;
  }
}
