import { Currencies } from '../Currencies';
import { Tax } from '../Tax';

export interface AnyoEvents {
  active: boolean;
  categoryId: string;
  categoryName?: string;
  circleIds?: string[];
  confirmedRegistrations?: number;
  description: string;
  durationMinutes: number;
  entryFee: EventEntryFee[];
  eventDate: Date | string;
  externalSpeakers?: ExternalSpeakers[];
  googleCalenderId?: string;
  id?: string;
  images?: string[];
  keyTakeAways: string;
  languages: string;
  maxRegistrations?: number;
  name: string;
  paymentType: string;
  presenterDetailIds: string[];
  presenterDetails?: [];
  registrationCutOffDate: Date | string;
  shareImage?: string;
  shareMessage?: string;
  shortLink?: string;
  tags?: string[];
  visibleFrom: Date | string;
  meetLink?: string;
  videoLink?: string;
  videoThumbnail?: string;
  testEvent?: boolean;
  testRealPayment?: boolean;
  squareImage?: string;
  recurring?: boolean;
  cronText?: string;
  rrText?: string;
  rrRule?: string;
  recurringText?: string;
  venue?: string;
  partnerIds?: string[];
  partners?: {
    id: string;
    name: string;
    partnerId: string;
  }[];
  offlineEvent?: boolean;
}

export interface ExternalSpeakers {
  name: string;
  email: string;
  description: string;
  qualification: string;
  socialMediaLinks: SocialMediaLinks;
}

export enum EventsTags {}

export interface EventEntryFee {
  id: string;
  active?: boolean;
  currency: Currencies;
  currencySymbol?: string;
  markupPrice: number;
  amount: number;
  tax: Tax[];
}

export enum EventPaymentType {
  FREE = 'Free',
  PAID = 'Paid',
  RETAIL_PAID = 'Retail Paid',
  CORPORATE_PAID = 'Corporate Paid',
}

export interface EventsMeta {}

export interface SocialMediaLinks {
  twitter?: string;
  web?: string;
  youtube?: string;
  faceBook?: string;
  linkedIn?: string;
}
