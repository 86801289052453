<ng-template #tableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Therapy Plans Subcriptions For Users</span>
      </div>
      <div class="flex gap-2">
        <nz-switch [(ngModel)]="showTestCount" (ngModelChange)="showTestUsers()" nzCheckedChildren="hide test user" nzUnCheckedChildren="show test user"></nz-switch>
        <div nz-col>
          <button
            class="btn-color"
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="resetFilter()"
          >
            Clear filter
          </button>
        </div>
        </div>
    </div>
  </ng-template>
  <ng-template #innerTableHead>
    <div nz-row nzJustify="space-between">
      <div nz-col>
        <span class="font-bold">Session Details</span>
      </div>
    </div>
  </ng-template>
  <div class="max-w-1xl mx-auto bg-white p-4 rounded-lg shadow-lg">
    <div class="flex flex-wrap pb-4 justify-start">
      <h1 class="headeradminui-text pl-2">{{ 'Manage Therapy Plans Subscriptions For Users'}}</h1>
    </div>
    <div style="background: #ECECEC; padding: 30px;">
      <nz-row [nzGutter]="16">
        <nz-col [nzSpan]="4">
          <nz-card>
            <nz-statistic
              [nzValue]="totalUsers"
              [nzTitle]="'Total Users'"
              [nzPrefix]="prefixTplOne"
              [nzValueStyle]="{ color: '#3F8600' }"
            ></nz-statistic>
            <ng-template #prefixTplOne><span nz-icon nzType="user" nzTheme="outline"></span></ng-template>
          </nz-card>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-card>
            <nz-statistic
              [nzValue]="totalActiveUsers"
              [nzTitle]="'Active Users'"
              [nzPrefix]="prefixTplOne"
              [nzValueStyle]="{ color: '#3F8600' }"
            ></nz-statistic>
          </nz-card>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-card>
            <nz-statistic
              [nzValue]="totalPlans"
              [nzTitle]="'Total Plans'"
              [nzPrefix]="prefixTplTwo"
              [nzValueStyle]="{ color: '#3F8600' }"
            ></nz-statistic>
            <ng-template #prefixTplTwo><span nz-icon nzType="arrow-up"></span></ng-template>
          </nz-card>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-card>
            <nz-statistic
              [nzValue]="totalActivePlans"
              [nzTitle]="'Active Plans'"
              [nzPrefix]="prefixTplTwo"
              [nzValueStyle]="{ color: '#3F8600' }"
            ></nz-statistic>
          </nz-card>
        </nz-col>
        <nz-col [nzSpan]="4">
          <nz-card>
            <nz-statistic
              [nzValue]="formatToRupees(retailValue)"
              [nzTitle]="'Retail Value'"
              [nzValueStyle]="{ color: '#3F8600' }"
            ></nz-statistic>
          </nz-card>
        </nz-col><nz-col [nzSpan]="4">
          <nz-card>
            <nz-statistic
              [nzValue]="formatToRupees(corporateValue)"
              [nzTitle]="'Corporate Value'"
              [nzValueStyle]="{ color: '#3F8600' }"
            ></nz-statistic>
          </nz-card>
        </nz-col>
      </nz-row>
    </div>

    <div class="flex justify-end mt-3 mb-3">
      <div class="w-[40%]">
        <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
          <input
            type="text"
            nz-input
            [(ngModel)]="globalSearchInput"
            placeholder="Search for user email,phone number"
          />
        </nz-input-group>
      </div>
    </div>
    <ng-template #suffixButton>
      <button
        nz-button
        nzType="primary"
        nzSize="small"
        nzSearch
        (click)="searchGlobal()"
      >
        Search
      </button>
    </ng-template>
   
    <nz-spin [nzSpinning]="pageLoading">
      <nz-table
        #nestedTable
        [nzTitle]="tableHead"
        [nzScroll]="{ y: '500px' }"
        [nzData]="therapyPlansSubscriptionsForEachUsers"
        [nzPageSize]="10"
        [nzBordered]="true"
      >
        <thead>
          <tr>
            <th nzWidth="20%">User Name</th>
            <th nzWidth="20%">User Email</th>
            <th>Phone No</th>
            <th>Total Plans</th>
            <th>Active Plans</th>
            <th>Completed Plans</th>
            <th>Total Plan Value</th>
            <th>Free Plans</th>
            <th>Paid Plans</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of nestedTable.data">
            <td><span class="cursor-pointer underline" (click)="goToFilterUsers(data.userId)">
              <nz-tag class="flex-wrap" [nzColor]="data.color">{{
                data.userName ? data.userName : "N/A"
              }}</nz-tag>
            </span></td>
            <td>{{ data.userEmail }}</td>
            <td>{{ data.phoneNumber ? data.phoneNumber : "N/A" }}</td>
            <td><nz-badge
              nzStandalone
              [nzCount]="data.totalPlans"
              [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }"
            ></nz-badge></td>
            <td>{{ data.activePlans }}</td>
            <td>{{ data.completedPlans }}</td>
            <td>{{ formatToRupees(data.totalPlanValue) }}</td>
            <td>{{ data.freePlans }}</td>
            <td>{{ data.paidPlans }}</td>
          </tr>
        </tbody>
      </nz-table>
    </nz-spin>
  </div>
  