import { Component } from '@angular/core';
import { UniqueTherapyPlanSubscriptionForUsers } from '../../../models/uniquePlanSubscriptionForUsers';
import { TherapyPlansService } from '../../../services/therapy-plans.service';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { UniqueTherapyPlanSubscriptionForUsersReq } from 'projects/admin/src/app/services/therapy-plans.service';
import { UtilsService } from 'projects/app-core/src/service/utils.service';

enum TestOrActive {
  TEST = 'test',
  ACTIVE = 'active'
}

@Component({
  selector: 'app-user-therapy-plans',
  templateUrl: './user-therapy-plans.component.html',
  styleUrl: './user-therapy-plans.component.scss'
})
export class UserTherapyPlansComponent {
  pageLoading = false
  therapyPlansSubscriptionsForEachUsers: UniqueTherapyPlanSubscriptionForUsers[] = [] 
  params = "active"
  showTestCount = false
  request!: UniqueTherapyPlanSubscriptionForUsersReq
  globalSearchInput: string = ''
  totalUsers = 0
  totalActiveUsers = 0
  totalActivePlans = 0
  totalPlans = 0
  retailValue = 0
  corporateValue = 0


  constructor(
    private plans: TherapyPlansService,
    private toastService: ToastService,
    private auth: AuthService,
    public utils: UtilsService
  ){}

  formatToRupees(value: number): string {
    return `₹${value.toLocaleString()}`
  }

  goToFilterUsers(userId: string){
    window.open(
      `therapy-plans/subscriptions?userId=${userId}`,
    );
  }

  showTestUsers(){
    if(!this.request){
      this.request = {
        globalSearch: [],
        activeOrTest: ''
      }
    }
    this.request.activeOrTest = this.showTestCount ? TestOrActive.TEST : TestOrActive.ACTIVE
    this.fetchList()
  }


  fetchList(){
    this.pageLoading = true
    this.plans.uniqueUserTherapySubslist(this.request).subscribe({
      next: (value) => {
        value.forEach((data) => {
          if(data.retailUser){
            this.retailValue += data.totalPlanValue
          }
          if(data.corporateUser){
            this.corporateValue += data.totalPlanValue
          }
          if(data.activePlans > 0){
          this.totalActiveUsers += 1
          }
          this.totalPlans += data.totalPlans
          this.totalActivePlans += data.activePlans
          data.color = this.utils.getRandomColor()
        })
        this.totalUsers = value.length
        this.therapyPlansSubscriptionsForEachUsers = value

        this.pageLoading = false;
      }, error: (error) => {
        this.pageLoading = false;
        const errorBody = error.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      }
    })
  }

  searchGlobal(){
    if(!this.request){
      this.request = {
        globalSearch: [],
        activeOrTest: this.showTestCount ? TestOrActive.TEST : TestOrActive.ACTIVE
      }
    }
    if(this.globalSearchInput.length){
      this.request.globalSearch = [this.globalSearchInput.trim()]
      this.fetchList()
    }
  }

  resetFilter(){
    this.globalSearchInput = ''
    this.request = {
      globalSearch: [],
      activeOrTest: TestOrActive.ACTIVE
    }
    this.showTestCount = false
    this.fetchList()
  }

  ngOnInit(){
    this.auth.currentAuthStatus.subscribe((user) => {
      if(user){
        this.pageLoading = true;
        if(!this.request){
          this.request = {
            globalSearch: [],
            activeOrTest: this.showTestCount ? TestOrActive.TEST : TestOrActive.ACTIVE
          }
        }
        this.fetchList()
      }
    })
  }
}
